import React, { useMemo, useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { profileSelector } from '../../store/modules/profile/selector'
import { profileDispatcher } from '../../store/modules/profile/dispatcher'
import { Layout } from './Layout'
import { signOut, checkAuth, loginWithTwitter } from '../../firebase/auth'

export const Container: React.FC = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const profile = useSelector(profileSelector.getProfile)
  const { setProfile } = useMemo(() => profileDispatcher(dispatch), [dispatch])

  const [isAtuhLoading, setAtuhLoading] = useState(true)
  const [isDrawerOpen, setDrawerOpen] = useState(false)

  useEffect(() => {
    checkAuth((user) => {
      if (user) {
        setProfile(user)
      }
      setAtuhLoading(false)
    })
  }, [setProfile])

  const handleDrawerToggle = useCallback(() => {
    setDrawerOpen(!isDrawerOpen)
  }, [isDrawerOpen])

  const handleDrawerClose = useCallback(() => {
    setDrawerOpen(false)
  }, [])

  const handleSignOut = useCallback(async () => {
    await signOut()
    setProfile(null)
    handleDrawerClose()
    history.push('/')
  }, [history, handleDrawerClose, setProfile])

  return (
    <Layout
      {...props}
      onLogin={loginWithTwitter}
      profile={profile}
      isAtuhLoading={isAtuhLoading}
      isDrawerOpen={isDrawerOpen}
      handleDrawerToggle={handleDrawerToggle}
      handleDrawerCloese={handleDrawerClose}
      handleSignOut={handleSignOut}
    />
  )
}
