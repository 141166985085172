import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { RootState } from '../../store/'
import { RoundButton } from './RoundButton'

type Props = {
  className?: string
  profile: RootState['profile']['profile']
  isLoading: boolean
  onLogin: () => void
}

export const Component = (props: Props) => {
  const history = useHistory()
  const handleBack = useCallback(
    (e: any) => {
      e.preventDefault()
      if (history.location.pathname.includes('mycat')) {
        history.push('/mypage')
        return
      }
      history.push('/')
    },
    [history]
  )
  const handleNew = useCallback(() => {
    history.push('/new')
  }, [history])
  return (
    <header className={props.className}>
      <div className="Left">
        {history.location.pathname !== '/' && (
          <button type="button" onClick={handleBack}>
            <img src="/images/ic-back.svg" width="32" height="32" alt="" />
          </button>
        )}
      </div>

      <h1 className="Logo">
        <a onClick={handleBack} href="/">
          NEKOTTER
        </a>
      </h1>

      <div className="Right">
        {!props.isLoading &&
          props.profile &&
          history.location.pathname !== '/new' && (
            <RoundButton label="投稿" onClick={handleNew} />
          )}
        {!props.isLoading && !props.profile && (
          <RoundButton label="ログイン" onClick={props.onLogin} />
        )}
      </div>
    </header>
  )
}

const StyledComponent = styled(Component)`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 58px;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  z-index: 100;
  > .Left {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
  }
  > .Right {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }
  > .Logo {
    font-weight: 700;
    font-size: 16px;
  }
`

export const Header = StyledComponent
