import fb from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

import { environments } from '../utils/environments'

if (!fb.apps.length) {
  fb.initializeApp({
    apiKey: environments.FIREBASE_API_KEY,
    authDomain: environments.FIREBASE_AUTH_DOMAIN,
    databaseURL: environments.FIREBASE_DATABASE_URL,
    projectId: environments.FIREBASE_PROJECT_ID,
    storageBucket: environments.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: environments.FIREBASE_MESSAGING_SENDER_ID
  })
}

export const fbAuth = fb.auth
export const fbStore = fb.firestore()
export const fbStorage = fb.storage ? fb.storage() : null
