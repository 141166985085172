import React from 'react'
import { ThemeProvider, css } from 'styled-components'
import colors, { ColorsTypes } from './colors'
import sizes, { SizesTypes } from './sizes'
import easings, { EasingsTypes } from './easings'
import mixins, { MixinsTypes } from './mixins'
import GlobalStyles from './GlobalStyles'

export type stylesTypes = {
  colors: ColorsTypes
  mixins: MixinsTypes
  easings: EasingsTypes
  sizes: SizesTypes
}

const styles: stylesTypes = {
  colors,
  sizes,
  easings,
  mixins
}

// mediaquery
// sample: ${mq.small`color: #000;`}
export const mq = Object.keys(sizes.mq).reduce((acc, label) => {
  acc[label] = (literals: TemplateStringsArray, ...placeholders: any[]) =>
    css`
      @media (max-width: ${sizes.mq[label]}px) {
        ${css(literals, ...placeholders)};
      }
    `
  return acc
}, {} as Record<keyof typeof sizes.mq, (l: TemplateStringsArray, ...p: any[]) => string>)

function ThemeWrapper({ children }) {
  return (
    <div
      style={{
        width: '100%',
        height: '100%'
      }}
    >
      <GlobalStyles />
      <ThemeProvider theme={styles}>{children}</ThemeProvider>
    </div>
  )
}

export default ThemeWrapper
