import React from 'react'
import { Container } from './Container'

type Props = {
  isDrawerOpen: boolean
  handleDrawerCloese: () => void
  handleSignOut: () => void
}

export const Drawer = (props: Props) => {
  return (
    <Container
      handleDrawerCloese={props.handleDrawerCloese}
      handleSignOut={props.handleSignOut}
      isDrawerOpen={props.isDrawerOpen}
    />
  )
}
