import React, { useState, useEffect, useCallback } from 'react'
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { profileSelector } from '../../../../store/modules/profile/selector'
import { Helmet } from 'react-helmet'
import { Layout } from './Layout'
import { getCatDetail, deleteCatDetail } from '../../../../firebase/store'

export const Container = () => {
  const profile = useSelector(profileSelector.getProfile)
  const location = useLocation()
  const history = useHistory()
  const march = useRouteMatch<{ id: string }>()
  const [cat, setCat] = useState<{
    author_id: string
    author_name: string
    created_at: {
      nanoseconds: number
      seconds: number
    }
    eyecatch: string
  } | null>(null)

  const handleShare = () => {
    if (cat) {
      const windowUrl = `http://twitter.com/share?url=${encodeURIComponent(
        `https://nekotter.com${location.pathname}`
      )}&text=${encodeURIComponent(
        `${cat.author_name}さんちの猫ちゃん | NEKOTTER \n#NEKOTTER\n`
      )}`
      window.open(
        windowUrl,
        'shareWindow',
        'width=540, height=430, personalbar=0, toolbar=0, scrollbars=1, sizable=1'
      )
    }
  }

  const handleDelete = useCallback(async () => {
    await deleteCatDetail(march.params.id)
    history.push('/mypage')
  }, [history, march.params.id])

  useEffect(() => {
    getCatDetail(march.params.id).then((res) => {
      setCat(res)
    })
  }, [march.params.id])

  // useEffect(() => {
  //   if (
  //     cat &&
  //     !cat.eyecatch.includes('.jpg') &&
  //     !cat.eyecatch.includes('.jpeg') &&
  //     !cat.eyecatch.includes('.JPG') &&
  //     !cat.eyecatch.includes('.JPEG') &&
  //     !cat.eyecatch.includes('.png')
  //   ) {
  //     setVideo(true)
  //   }
  // }, [cat])

  return (
    <>
      {cat && (
        <Helmet>
          <title>{`${cat.author_name}さんちの猫ちゃん`} | NEKOTTER</title>
          <meta
            property="og:url"
            content={`https://nekotter.com/cat/${march.params.id}`}
          />
          <meta
            property="og:title"
            content={`${cat.author_name}さんちの猫ちゃん | NEKOTTER`}
          />
          <meta property="og:image" content={cat.eyecatch} />
          <meta property="twitter:image" content={cat.eyecatch} />
        </Helmet>
      )}
      <Layout
        cat={cat}
        handleShare={handleShare}
        handleDelete={handleDelete}
        profile={profile}
      />
    </>
  )
}
