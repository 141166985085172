import { v1 as uuidv1 } from 'uuid'
import { fbStorage } from './common'

/**
 * firebase storageへ画像アップロード
 * @param image - File
 */
export const uploadImage = async (image: File) => {
  if (fbStorage) {
    const uuid = uuidv1()
    const ref = fbStorage.ref(`images/cat/${uuid}-${image.name}`)
    await ref.put(image)
    const url = await ref.getDownloadURL()

    return url
  }
  return null
}
