import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { profileSelector } from '../../../store/modules/profile/selector'
import { getMyCats } from '../../../firebase/store'
import { Layout } from './Layout'

export const Container = () => {
  const profile = useSelector(profileSelector.getProfile)
  const [cats, setCats] = useState<
    Array<{
      author_id: string
      author_name: string
      created_at: {
        nanoseconds: number
        seconds: number
      }
      eyecatch: string
      id: string
    }>
  >([])

  useEffect(() => {
    if (profile) {
      getMyCats(profile.uid).then((res) => {
        setCats(res)
      })
    }
  }, [profile])

  return (
    <>
      <Helmet>
        <meta property="og:url" content="https://nekotter.com" />
        <meta property="og:title" content="NEKOTTER" />
        <meta
          property="og:image"
          content="https://nekotter.com/images/ogp.jpg"
        />
      </Helmet>

      <Layout myCats={cats} />
    </>
  )
}
