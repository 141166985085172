import React, { useCallback } from 'react'
import { Layout } from './Layout'
import { useHistory } from 'react-router-dom'

type Props = {
  isDrawerOpen: boolean
  handleDrawerCloese: () => void
  handleSignOut: () => void
}

export const Container = (props: Props) => {
  const history = useHistory()
  const handleNavigate = useCallback(
    (path: string) => {
      history.push(path)
      props.handleDrawerCloese()
    },
    [history, props]
  )

  return (
    <Layout
      handleDrawerCloese={props.handleDrawerCloese}
      handleSignOut={props.handleSignOut}
      handleNavigate={handleNavigate}
      isDrawerOpen={props.isDrawerOpen}
    />
  )
}
