import React from 'react'
import styled from 'styled-components'
import { AppLayout } from '../../AppLayout'
import { SimpleLoader } from '../../common/SimpleLoader'

type Props = {
  className?: string
  imageUrl: string
  isUploading: boolean
  handleImageSelect: (e) => void
  handlePost: () => void
}

export const Component = (props: Props) => {
  return (
    <AppLayout>
      <div className={props.className}>
        <label className="Upload">
          {/* <video
            src="{imageUrl}"
            playsinline
            loop
            muted
            autoplay
            controls="{false}"
          ></video> */}
          {props.imageUrl.length ? <img src={props.imageUrl} alt="" /> : null}
          {!props.imageUrl.length ? (
            <>
              <input
                type="file"
                onChange={props.handleImageSelect}
                accept="image/png,image/jpeg"
                multiple={false}
              />
              <span>猫ちゃんを選ぶ</span>
            </>
          ) : null}
        </label>

        <button className="Submit" type="button" onClick={props.handlePost}>
          投稿
        </button>

        {props.isUploading && <SimpleLoader />}
      </div>
    </AppLayout>
  )
}

const StyledComponent = styled(Component)`
  padding: 16px;
  > .Upload {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80px;
    padding: 16px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;

    input {
      display: none;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      &::before {
        content: '';
        width: 32px;
        height: 32px;
        background: url('/images/cat.png') no-repeat center;
        background-size: contain;
      }
    }
    img {
      width: 100%;
      height: auto;
    }
    video {
      width: 100%;
      height: auto;
    }
  }
  > .Submit {
    display: block;
    width: 200px;
    height: 32px;
    margin: 40px auto 0;
    background-color: #1da1f2;
    border-radius: 20px;
    font-size: 12px;
    color: #fff;
    letter-spacing: 0.87px;
    text-align: center;
    line-height: 32 / 13;
  }
`

export const Layout = StyledComponent
