import { constants } from './constants'
import { State } from './index'
// =================================
// action creator

export const setProfile = (payload: State['profile']) =>
  ({
    type: constants.SET_PROFILE,
    payload
  } as const)
