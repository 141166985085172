import React from 'react'
import styled from 'styled-components'

type Props = {
  className?: string
}

export const Component = (props: Props) => {
  return (
    <div className={props.className}>
      <div className="Inner">
        <img src="/images/cat.png" width="48" height="48" alt="" />
        <span>ちょっとまってね</span>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.12);
  z-index: 101;
  > .Inner {
    text-align: center;
    span {
      display: block;
      padding-top: 8px;
      font-size: 16px;
      font-weight: bold;
    }
  }
`

export const SimpleLoader = StyledComponent
