import { Reducer } from 'redux'
import { ActionType } from 'redux-actions-type'
import { constants } from './constants'
import * as actions from './actions'

export type Action = ActionType<typeof actions>

// =============================================
// State

export type State = {
  profile: {
    name: string
    uid: string
    thumbnail: string
  } | null
}

export const initialState: State = {
  profile: null
}

// =============================================
// Reducer

const reducer: Reducer<State, Action> = (
  state = initialState,
  action: Action
) => {
  switch (action.type) {
    case constants.SET_PROFILE:
      return { ...state, profile: action.payload }

    default:
      return state
  }
}

export const profileReduser = reducer
