import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { getCats } from '../../../firebase/store'
import { Layout } from './Layout'

export const Container = () => {
  const [cats, setCats] = useState<
    Array<{
      author_id: string
      author_name: string
      created_at: {
        nanoseconds: number
        seconds: number
      }
      eyecatch: string
      id: string
    }>
  >([])

  useEffect(() => {
    getCats().then((res) => {
      setCats(res)
    })
  }, [])

  return (
    <>
      <Helmet>
        <meta property="og:url" content="https://nekotter.com" />
        <meta property="og:title" content="NEKOTTER" />
        <meta
          property="og:image"
          content="https://nekotter.com/images/ogp.jpg"
        />
      </Helmet>
      <Layout cats={cats} />
    </>
  )
}
