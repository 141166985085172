import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import ThemeWrapper from './components/styles'
import configureStore from './store/index'

import { Home } from './components/pages/home'
import { MyPage } from './components/pages/mypage'
import { New } from './components/pages/new'
import { CatDetail } from './components/pages/cat/detail'
import { MyCatDetail } from './components/pages/mycat/detail'

export const App = () => {
  const reduxStore = configureStore()

  return (
    <Provider store={reduxStore}>
      <ThemeWrapper>
        <Router>
          <Switch>
            <Route path="/cat/:id">
              <CatDetail />
            </Route>
            <Route path="/mycat/:id">
              <MyCatDetail />
            </Route>
            <Route path="/mypage">
              <MyPage />
            </Route>
            <Route path="/new">
              <New />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Router>
      </ThemeWrapper>
    </Provider>
  )
}
