import React from 'react'
import styled from 'styled-components'

type Props = {
  className?: string
  isDrawerOpen: boolean
  handleNavigate: (path: string) => void
  handleDrawerCloese: () => void
  handleSignOut: () => void
}

export const Component = (props: Props) => {
  return (
    <div className={props.className}>
      <div
        className={`Bg ${props.isDrawerOpen ? 'isActive' : ''}`}
        onClick={props.handleDrawerCloese}
      ></div>
      <div className={`Container ${props.isDrawerOpen ? 'isActive' : ''}`}>
        <h1 className="Logo">NEKOTTER</h1>
        <ul className="List">
          <li>
            <button
              onClick={() => {
                props.handleNavigate('/mypage')
              }}
            >
              マイページ
            </button>
          </li>
          <li>
            <button onClick={props.handleSignOut}>ログアウト</button>
          </li>
        </ul>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)`
  > .Bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.54);
    opacity: 0;
    z-index: 100;
    transition: 0.3s ease;
    visibility: hidden;
    &.isActive {
      opacity: 1;
      visibility: inherit;
    }
  }
  > .Container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 280px;
    background-color: white;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    z-index: 100;
    &.isActive {
      transform: translateX(0);
    }
    > .Logo {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 58px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      font-weight: 700;
      font-size: 16px;
    }
    > .List {
      li {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        button {
          display: block;
          width: 100%;
          padding: 8px 16px;
          font-size: 14px;
          line-height: (24/14);
          text-align: left;
        }
      }
    }
  }
`

export const Layout = StyledComponent
