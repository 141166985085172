import { applyMiddleware, createStore, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { environments } from '../utils/environments'
import { profileReduser } from './modules/profile'

export type RootState = {
  profile: ReturnType<typeof profileReduser>
}

const configureStore = (initialState = {}) => {
  const reducers = combineReducers({
    profile: profileReduser
  })

  const middleware =
    environments.NODE_ENV !== 'production'
      ? composeWithDevTools()
      : applyMiddleware()
  const store = createStore(reducers, initialState, middleware)

  return store
}

export default configureStore
