import React, { useState, useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { loadingImage } from '../../utils'

type Props = {
  className?: string
  cat: {
    author_id: string
    author_name: string
    created_at: {
      nanoseconds: number
      seconds: number
    }
    eyecatch: string
    id: string
  }
  link: string
}

export const Component = (props: Props) => {
  const history = useHistory()
  const [image, setImage] = useState<string | null>(null)
  const refTarget = useRef<HTMLElement | null>(null)
  // const [isVideoLoaded, setVideoLoaded] = useState(false)

  // const handleVideoLoad = useCallback(() => {
  //   setVideoLoaded(true)
  // }, [])

  const handleNavigateDetail = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault()
      history.push(props.link)
    },
    [history, props.link]
  )

  useEffect(() => {
    // if (!isVideo) {
    if (refTarget.current) {
      const options = {
        root: document.querySelector('body')
      }
      const observer = new IntersectionObserver(async (entries) => {
        for (const e of entries) {
          if (e.isIntersecting) {
            await loadingImage(props.cat.eyecatch).catch(() => {
              setImage(props.cat.eyecatch)
              return
            })
            setImage(props.cat.eyecatch)
          }
        }
      }, options)
      observer.observe(refTarget.current as Element)
    }
    // }
  }, [props.cat.eyecatch])

  return (
    <article className={props.className} ref={refTarget}>
      <a href={props.link} onClick={handleNavigateDetail}>
        <figure>
          <span className="Thumb">
            <span style={{ backgroundImage: `url(${image})` }}>
              {!image && <span className="placeholder"></span>}
            </span>
            {/* <video
            src={props.cat.eyecatch}
            autoplay
            muted
            loop
            playsinline
            onLoadeddata={handleVideoLoad}
          ></video>
            {<span
              className="placeholder"
            ></span>} */}
          </span>
          <figcaption>{`${props.cat.author_name}さんちの猫ちゃん`}</figcaption>
        </figure>
      </a>
    </article>
  )
}

const StyledComponent = styled(Component)`
  & + & {
    margin-top: 32px;
  }
  a {
    display: block;
  }
  figure {
    .Thumb {
      position: relative;
      display: block;
      overflow: hidden;
      border-radius: 8px;
      z-index: 1;
      &::after {
        content: '';
        display: block;
        padding-bottom: calc(9 / 16 * 100%);
      }
      span {
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: no-repeat center;
        background-size: cover;
      }
      video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: auto;
        z-index: 1;
      }
      .placeholder {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: url('/images/placeholder.jpg') no-repeat center;
        background-size: cover;
      }
    }
    figcaption {
      display: block;
      padding-top: 4px;
      font-size: 14px;
      text-align: right;
    }
  }
`

export const ListItem = StyledComponent
