import { DispatchProp } from 'react-redux'
import * as actions from './actions'
import { State } from './index'

const dispatcher = (dispatch: DispatchProp['dispatch']) => {
  const execDispatch = dispatch

  // ============================================================
  const setProfile = (payload: State['profile']) => {
    execDispatch(actions.setProfile(payload))
  }
  return {
    setProfile
  }
}

export const profileDispatcher = dispatcher
