import React from 'react'
import styled from 'styled-components'
import { AppLayout } from '../../AppLayout'
import { ListItem } from '../../common/ListItem'

type Props = {
  className?: string
  myCats: Array<{
    author_id: string
    author_name: string
    created_at: {
      nanoseconds: number
      seconds: number
    }
    eyecatch: string
    id: string
  }>
}

export const Component = (props: Props) => {
  return (
    <AppLayout>
      <div className={props.className}>
        {props.myCats.map((cat) => (
          <ListItem cat={cat} key={cat.id} link={`/mycat/${cat.id}`} />
        ))}
      </div>
    </AppLayout>
  )
}

const StyledComponent = styled(Component)`
  padding: 16px 16px 40px;
`

export const Layout = StyledComponent
