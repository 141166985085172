import React from 'react'
import styled from 'styled-components'

type Props = {
  className?: string
  onClick: () => void
  label: string
}

export const Component = (props: Props) => {
  return (
    <button onClick={props.onClick} type="button" className={props.className}>
      {props.label}
    </button>
  )
}

const StyledComponent = styled(Component)`
  width: 80px;
  height: 32px;
  background-color: #1da1f2;
  border-radius: 20px;
  font-size: 12px;
  color: #fff;
  letter-spacing: 0.87px;
  text-align: center;
  line-height: ${32 / 12};
`

export const RoundButton = StyledComponent
