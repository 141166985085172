import { fbAuth } from './common'

export const loginWithTwitter = async () => {
  const provider = new fbAuth.TwitterAuthProvider()
  fbAuth().languageCode = 'jp'
  await fbAuth()
    .signInWithRedirect(provider)
    .catch((error) => {
      console.log(new TypeError(error))
    })
}

export const signOut = async () => {
  await fbAuth().signOut()
}

export const checkAuth = (
  callback: (data?: { name: string; uid: string; thumbnail: string }) => void
) => {
  fbAuth().onAuthStateChanged((user) => {
    if (user) {
      callback({
        name: user.displayName || '',
        uid: user.uid,
        thumbnail: user.photoURL || ''
      })
      return
    }
    callback()
  })
}
