import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { profileSelector } from '../../../store/modules/profile/selector'
import { uploadImage } from '../../../firebase/storage'
import { postCat } from '../../../firebase/store'
import { Layout } from './Layout'

export const Container = () => {
  const history = useHistory()
  const profile = useSelector(profileSelector.getProfile)
  const [imageFile, setImageFile] = useState<File | null>(null)
  const [imageUrl, setImageUrl] = useState('')
  const [isUploading, setUploading] = useState(false)

  const extractLocalImageUrl = (file: File) => {
    return new Promise((resolve) => {
      const fileReader = new FileReader()
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      if (file) {
        fileReader.readAsDataURL(file)
      }
    })
  }

  const handleImageSelect = async (e) => {
    const target = e.target
    if (target.files) {
      const file: File = target.files[0]
      const url = await extractLocalImageUrl(file)
      setImageFile(file)
      setImageUrl(url as string)
    }
  }

  const handleSubmit = async () => {
    if (!isUploading) {
      if (imageFile) {
        setUploading(true)
        const imageUrl = await uploadImage(imageFile)

        if (profile) {
          await postCat({
            author_name: profile.name,
            author_id: profile.uid,
            created_at: new Date(),
            eyecatch: imageUrl
          })
        }
        setUploading(false)

        history.push('/')
      }
    }
  }

  return (
    <>
      <Helmet>
        <meta property="og:url" content="https://nekotter.com" />
        <meta property="og:title" content="NEKOTTER" />
        <meta
          property="og:image"
          content="https://nekotter.com/images/ogp.jpg"
        />
      </Helmet>
      <Layout
        handleImageSelect={handleImageSelect}
        handlePost={handleSubmit}
        imageUrl={imageUrl}
        isUploading={isUploading}
      />
    </>
  )
}
