import React from 'react'
import styled from 'styled-components'
import { RootState } from '../../store/'
import { Header } from '../common/Header'
import { Drawer } from '../Drawer/index'

type Props = {
  className?: string
  onLogin: () => void
  profile: RootState['profile']['profile']
  isAtuhLoading: boolean
  isDrawerOpen: boolean
  handleDrawerToggle: () => void
  handleDrawerCloese: () => void
  handleSignOut: () => void
}

export const Component: React.FC<Props> = (props) => {
  return (
    <div className={props.className}>
      <Header
        onLogin={props.onLogin}
        profile={props.profile}
        isLoading={props.isAtuhLoading}
      />
      <main>{props.children}</main>

      <Drawer
        handleDrawerCloese={props.handleDrawerCloese}
        isDrawerOpen={props.isDrawerOpen}
        handleSignOut={props.handleSignOut}
      />

      {props.profile && (
        <button className="Button" onClick={props.handleDrawerToggle}>
          <img
            src={`/images/ic-${props.isDrawerOpen ? 'cross' : 'hamburger'}.svg`}
            alt=""
          />
        </button>
      )}
    </div>
  )
}

const StyledComponent = styled(Component)`
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  > main {
    max-width: 768px;
    margin: 0 auto;
    padding-top: 58px;
  }
  > .Button {
    position: fixed;
    bottom: 16px;
    right: 16px;
    width: 52px;
    height: 52px;
    padding: 8px;
    border-radius: 50%;
    background-color: #1da1f2;
    cursor: pointer;
    z-index: 101;
  }
`

export const Layout = StyledComponent
