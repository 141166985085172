import React from 'react'
import styled from 'styled-components'
import { AppLayout } from '../../../AppLayout'
import { RootState } from '../../../../store/'

type Props = {
  className?: string
  handleShare: () => void
  handleDelete: () => void
  profile: RootState['profile']['profile']
  cat: {
    author_id: string
    author_name: string
    created_at: {
      nanoseconds: number
      seconds: number
    }
    eyecatch: string
  } | null
}

export const Component = (props: Props) => {
  return (
    <AppLayout>
      {props.cat && (
        <div className={props.className}>
          <figure>
            <img
              src={props.cat.eyecatch}
              alt={`${props.cat.author_name}さんちの猫ちゃん`}
            />
          </figure>

          {/* <video
            src="{cat.eyecatch}"
            playsinline
            loop
            muted
            autoplay
            controls="{false}"
          ></video> */}
          <h1>{`${props.cat.author_name}さんちの猫ちゃん`}</h1>

          {props.cat.author_id === props.profile?.uid && (
            <button
              className="Delete"
              type="button"
              onClick={props.handleDelete}
            >
              投稿を削除
            </button>
          )}
        </div>
      )}
    </AppLayout>
  )
}

const StyledComponent = styled(Component)`
  padding: 16px 16px 40px;
  > h1 {
    display: block;
    padding-top: 4px;
    font-size: 14px;
    text-align: right;
  }
  > video {
    width: 100%;
    height: auto;
  }
  > .Share {
    display: block;
    width: 240px;
    height: 40px;
    margin: 40px auto 0;
    background-color: #1da1f2;
    border-radius: 20px;
    font-size: 14px;
    color: #fff;
    letter-spacing: 0.87px;
    text-align: center;
    line-height: 32 / 14;
  }
  > .Delete {
    display: block;
    width: 240px;
    height: 40px;
    margin: 40px auto 0;
    background-color: #ff3b30;
    border-radius: 20px;
    font-size: 14px;
    color: #fff;
    letter-spacing: 0.87px;
    text-align: center;
    line-height: 32 / 14;
  }
`

export const Layout = StyledComponent
