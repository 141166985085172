import { fbStore } from './common'

export const postCat = async (data: {
  author_name: string
  author_id: string
  created_at: Date
  eyecatch: string
}) => {
  const docRef = await fbStore.collection('cats').add(data)
  return docRef.id
}

export const getCats = async () => {
  const catsData: Array<{
    author_id: string
    author_name: string
    created_at: {
      nanoseconds: number
      seconds: number
    }
    eyecatch: string
    id: string
  }> = []
  const cats = await fbStore
    .collection('cats')
    .orderBy('created_at', 'desc')
    .limit(50)
    .get()
  if (!cats.empty) {
    cats.forEach((cat) => {
      const data = cat.data() as
        | {
            author_id: string
            author_name: string
            created_at: {
              nanoseconds: number
              seconds: number
            }
            eyecatch: string
          }
        | undefined
      if (data) {
        catsData.push({ ...data, id: cat.id })
      }
    })
  }

  return catsData
}

export const getMyCats = async (uid: string) => {
  const catsData: Array<{
    author_id: string
    author_name: string
    created_at: {
      nanoseconds: number
      seconds: number
    }
    eyecatch: string
    id: string
  }> = []

  const cats = await fbStore
    .collection('cats')
    .where('author_id', '==', uid)
    .orderBy('created_at', 'desc')
    .limit(60)
    .get()
  if (!cats.empty) {
    cats.forEach((cat) => {
      const data = cat.data() as
        | {
            author_id: string
            author_name: string
            created_at: {
              nanoseconds: number
              seconds: number
            }
            eyecatch: string
          }
        | undefined
      if (data) {
        catsData.push({ ...data, id: cat.id })
      }
    })
  }

  return catsData
}

export const getCatDetail = async (id: string) => {
  const shopDoc = await fbStore.collection('cats').doc(id).get()

  return shopDoc.data() as {
    author_id: string
    author_name: string
    created_at: {
      nanoseconds: number
      seconds: number
    }
    eyecatch: string
  }
}

export const deleteCatDetail = async (id: string) => {
  await fbStore.collection('cats').doc(id).delete()

  return true
}
