export type SizesTypes = typeof sizes

const sizes = {
  pc: {
    innerWidth: 1140,
    sidePadding: 16
  },
  sp: {},
  mq: {
    small: 538
  }
} as const

export default sizes
